import { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ParsedUrlQueryInput } from 'node:querystring';

import { Link, Props as LinkProps } from '@common/elements/Link';
import { useContextData } from '@common/useContextData';
import { formatInternalRoute, Route } from '@web/routing';

export interface Props extends Omit<LinkProps, 'href'> {
    route: Route;
    query?: ParsedUrlQueryInput;
}

export const InternalLink: FC<Props> = ({ route, query, children, ...otherProps }) => {
    const { routePrefix } = useContextData();
    const __url = useTranslation('url').t;
    const path = useMemo(() => formatInternalRoute(route, routePrefix, __url), [route, routePrefix, __url]);
    return (
        <Link
            href={{
                pathname: path,
                query: query,
            }}
            {...otherProps}
        >
            {children}
        </Link>
    );
};
