import dynamic from 'next/dynamic';
import { SVGAttributes, memo, MemoExoticComponent, ReactElement } from 'react';
import LogoTwitter from '@common/logos/twitter-logo.svg';

const Logo18plus = dynamic(() => import('@common/logos/18-plus.svg'));
const Logo18plusInline = dynamic(() => import('@common/logos/18-plus-inline.svg'));
const LogoFacebook = dynamic(() => import('@common/logos/facebook.svg'));
const LogoLinkedin = dynamic(() => import('@common/logos/linkedIn.svg'));
const LogoLink = dynamic(() => import('@common/logos/link-share.svg'));
const LogoSecureBetting = dynamic(() => import('@common/logos/info-secure-betting.svg'));
const LogoUnibet = dynamic(() => import('@common/logos/unibet-logo.svg'));
const LogoUnibetDark = dynamic(() => import('@common/logos/unibet-logo-dark.svg'));
const LogoWhatsapp = dynamic(() => import('@common/logos/whatsapp.svg'));

export enum Option {
    eighteenPlus,
    eighteenPlusInline,
    facebook,
    linkedIn,
    link,
    secureBetting,
    twitter,
    unibet,
    unibetDark,
    whatsapp,
}

const LogoByOption = (option: Option): typeof LogoTwitter | null => {
    switch (option) {
        case Option.eighteenPlus:
            return Logo18plus;
        case Option.eighteenPlusInline:
            return Logo18plusInline;
        case Option.facebook:
            return LogoFacebook;
        case Option.linkedIn:
            return LogoLinkedin;
        case Option.link:
            return LogoLink;
        case Option.secureBetting:
            return LogoSecureBetting;
        case Option.twitter:
            return LogoTwitter;
        case Option.unibet:
            return LogoUnibet;
        case Option.unibetDark:
            return LogoUnibetDark;
        case Option.whatsapp:
            return LogoWhatsapp;
    }
};

const getIsSquare = (option: Option): boolean => {
    switch (option) {
        case Option.linkedIn:
        case Option.twitter:
        case Option.facebook:
        case Option.link:
        case Option.whatsapp:
            return true;
        default:
            return false;
    }
};

interface Props {
    onClick?: () => void;
}

type Type = {
    [key: string]: MemoExoticComponent<(props: Props) => JSX.Element>;
};

const LogoElement = (type: Option) => {
    return (props: Props): ReactElement => {
        const classes = ['Logo', Option[type] + '-logo'];

        let _props = {
            ...props,
            className: classes.join(' '),
            style: {
                cursor: props.onClick ? 'pointer' : '',
            },
        };

        let _svgProps: SVGAttributes<SVGSVGElement> = {
            width: '100%',
            height: '100%',
        };

        const isSquare = getIsSquare(type);
        if (isSquare) {
            _svgProps.viewBox = '0 0 24 24';
            _svgProps.preserveAspectRatio = 'xMidYMid meet';
        }

        const ChosenLogo = LogoByOption(type);
        if (ChosenLogo) {
            // FIXME: webpack couldn't properly import style from common folder
            return (
                <span {..._props}>
                    {isSquare ? (
                        <style jsx>{`
                            .Logo {
                                display: inline-block;
                                vertical-align: top;
                                width: 1em;
                                height: 1em;
                            }
                        `}</style>
                    ) : (
                        <style jsx>{`
                            .Logo {
                                display: inline-block;
                            }
                        `}</style>
                    )}
                    <ChosenLogo {..._svgProps} />
                </span>
            );
        } else {
            return <span {..._props}>Logo.{type} is not yet implemented</span>;
        }
    };
};

let logo: Type = {};
for (let key in Option) {
    if (isNaN(+key)) {
        const option: Option = Option[key as keyof typeof Option];
        logo[key] = memo(LogoElement(option));
    } else {
        const option: Option = Option[Option[key] as keyof typeof Option];
        logo[Number(key)] = memo(LogoElement(option));
    }
}

export const Logo = logo;
