import { FC, ReactNode, ReactElement } from 'react';
import BaseLink, { LinkProps } from 'next/link';

export interface Props extends LinkProps {
    children?: ReactNode;
    className?: string;
    isSponsored?: boolean;
}

export const Link: FC<Props> = ({ isSponsored, ...props }) => {
    const newProps: LinkProps = {
        ...props,
        passHref: true,
    };

    if (props.shallow === undefined) newProps.shallow = false;
    if (props.prefetch === undefined) newProps.prefetch = false;
    if (props.locale === undefined) newProps.locale = false;

    if (isSponsored) {
        return (
            <BaseLink rel="sponsored nofollow" target="_blank" className={props.className} {...newProps}>
                {props.children}
            </BaseLink>
        );
    }

    if ((props.children as ReactElement)?.type === 'a') {
        // FIXME: get rid of legacyBehavior
        return (
            <BaseLink {...newProps} legacyBehavior>
                {props.children}
            </BaseLink>
        );
    }

    return (
        <BaseLink {...newProps} legacyBehavior>
            <a
                className={props.className}
                onClick={(e) => {
                    // FIXME: Somehow the shallow=false is not working properly
                    if (!props.shallow && typeof props.href === 'string') {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.href = props.href;
                    }
                }}
            >
                {props.children}
            </a>
        </BaseLink>
    );
};
